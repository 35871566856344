import { createApp } from "vue";
import { createVfm } from "vue-final-modal";
import App from "./App.vue";
import router from "./router";
import "vue-final-modal/style.css";
import "./index.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "../src/assets/css/fontawesome-all.min.css";
import "animate.css/animate.min.css";
import "../src/assets/scss/style.scss";
import "swiper/css";
import "swiper/css/effect-fade";

const vfm = createVfm();

createApp(App).use(router).use(vfm).mount("#app");
