<template>
  <router-view />
  <ModalsContainer />
</template>

<script>
  import { ModalsContainer } from "vue-final-modal";
  import WOW from "wow.js";
  export default {
    name: "app",
    mounted() {
      new WOW().init();
    },
  };
</script>
