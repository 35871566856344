import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "NotFound" */ "../views/elements/404.vue"),
  },
  {
    path: "/home",
    redirect: "/",
  },
  {
    path: "/",
    name: "HomeRugi",
    props: true,
    component: () => import(/* webpackChunkName: "HomeRugi" */ "../views/HomeRugi.vue"),
  },
  {
    path: "/history",
    name: "HistoryRugi",
    props: true,
    component: () => import(/* webpackChunkName: "HistoryRugi" */ "../views/HistoryRugi.vue"),
  },
  {
    path: "/lavora-con-noi",
    name: "WorkWithUse",
    props: true,
    component: () => import(/* webpackChunkName: "HistoryRugi" */ "../views/WorkWithUseRugi.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      const differentPage = to.path !== from.path;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ el: to.hash, behavior: "smooth", top: 20 });
        }, 50);
      });
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ top: 0, behavior: "smooth" });
        }, 50);
      });
    }
  },
});

export default router;
